import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { useToast } from '@/utils/atoms/toast';
import { useModal } from '@/utils/hooks/useModal';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { ApolloError, gql } from '@apollo/client';
import {
  Box,
  Button,
  ButtonProps,
  Center,
  Flex,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useModalContext,
} from '@chakra-ui/react';
import { useNavigate } from '@remix-run/react';
import { lazy, useState } from 'react';
import { IconType } from 'react-icons';
import { FaCheckCircle, FaClipboard } from 'react-icons/fa';
import { useAssetByQRcodeQuery } from '../graphql/assets.generated';
import { formatAssetName } from '../utils';
import { Asset_QrScannerModalFragment } from './QrScannerModalContent.generated';
const QrScanner = lazy(() => import('@/common/components/QrScanner'));

type ModalActionType = 'detail' | 'createWorkOrder' | 'createRequest';
type QrScannerOptionModalActionType = {
  props: ButtonProps;
  textKey: string;
  type: ModalActionType;
  icon?: IconType;
};

type QrScannerModalProps = {
  workOrderCreateButtonClicked?: (assetId?: number) => void;
};

const optionChildren: QrScannerOptionModalActionType[] = [
  {
    props: {
      colorScheme: 'primary',
    },
    icon: FaCheckCircle,
    textKey: 'actions.create-task',
    type: 'createWorkOrder',
  },
  {
    props: {
      colorScheme: 'primary',
    },
    icon: FaClipboard,
    textKey: 'actions.create-request',
    type: 'createRequest',
  },
  {
    props: {
      colorScheme: 'gray',
      variant: 'outline',
    },
    textKey: 'actions.asset-info',
    type: 'detail',
  },
];

gql`
fragment Asset_QrScannerModal on Asset {
  id
  name
  parentAsset {
    id
    name
    parentAsset {
      id
      name
      parentAsset {
        id
        name
        parentAsset {
          id
          name
        }
      }
    }
  }
}
`;
gql`
query QrScannerModal($qrcode: String!) {
  assetByQRcode(qrcode: $qrcode) {
    ...Asset_QrScannerModal
  }
}
`;

const QrScannerModalContent = ({ workOrderCreateButtonClicked }: QrScannerModalProps) => {
  const modalContext = useModalContext();
  const { element: ErrorModal, open: onErrorModalOpen } = useModal({
    Component: QrScannerModalErrorContent,
  });
  const { element: OptionModal, open: onOptionModalOpen } = useModal({
    Component: QrScannerOptionSelectModalContent,
    size: { base: 'full', md: 'sm' },
  });

  const { toast } = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { isMobile } = useScreenInfos();
  const { t_toasts } = useTranslation();

  // NOTE: For some reason useLazyQuery doesn't work as expected. await response not returned always awaiting and sending same request 2 times not working
  const { refetch: getAssetByQRcode } = useAssetByQRcodeQuery({
    skip: true,
  });

  const [shouldPauseScan, setPauseScan] = useState<boolean>(false);

  const onQrModalCloseHandler = () => {
    setPauseScan(false);
    modalContext.onClose();
  };

  const onReScan = () => {
    setPauseScan(false);
    // 呼び出しているmodalContext.onClose()を呼ぶと、モーダルの中身が閉じられ、QrScannerが再描画されないため、再度開く
    modalContext.isOpen = true;
  };

  const oScanResult = async (result: string) => {
    setPauseScan(true);
    let barcode = result;
    if (result.includes(`${window.location.origin}/qr-barcode/assets/`))
      barcode = result.replace(`${window.location.origin}/qr-barcode/assets/`, '');

    setLoading(true);
    try {
      const { data } = await getAssetByQRcode({ qrcode: barcode });
      if (data) {
        onOptionModalOpen({
          asset: data.assetByQRcode,
          onClose: onQrModalCloseHandler,
          onReScan: () => setPauseScan(false),
          workOrderCreateButtonClicked,
        });
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        if (
          error.graphQLErrors.some(
            (onAssetFetchError) => onAssetFetchError.extensions?.code === '404'
          )
        ) {
          onErrorModalOpen({
            onReScan: onReScan,
          });
        } else {
          throw error;
        }
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  const onScanFail = (messageKey: string) => {
    console.error(messageKey);
    toast({
      title: t_toasts(messageKey),
      status: 'error',
    });
    onQrModalCloseHandler();
  };

  return (
    <>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton zIndex={100} size='lg' color='neutral.0' />
        <ModalBody p={0}>
          {!shouldPauseScan && (
            <SuspenseWithSpinner>
              <QrScanner
                shouldPauseScan={shouldPauseScan}
                qrbox={250}
                onSuccess={oScanResult}
                onFail={onScanFail}
                aspectRatio={
                  isMobile
                    ? window.innerHeight / window.innerWidth
                    : window.innerWidth / window.innerHeight
                }
              />
            </SuspenseWithSpinner>
          )}

          {isLoading ? (
            <Box
              pos='absolute'
              top='0'
              left='0'
              right='0'
              bottom='0'
              bg='blackAlpha.600'
              borderRadius='md'
              zIndex='3'
            >
              <Center height='100%'>
                <Spinner size='xl' thickness='4px' color='white'></Spinner>
              </Center>
            </Box>
          ) : (
            <></>
          )}
        </ModalBody>
      </ModalContent>
      {ErrorModal}
      {OptionModal}
    </>
  );
};

const QrScannerOptionSelectModalContent = ({
  asset,
  onClose,
  onReScan,
  workOrderCreateButtonClicked,
}: {
  asset: Asset_QrScannerModalFragment;
  onClose: () => void;
  onReScan: () => void;
  workOrderCreateButtonClicked?: (assetId: number) => void;
}) => {
  const { t } = useTranslation();
  const modalContext = useModalContext();
  const navigate = useNavigate();

  const onOptionClicked = (type: string) => {
    modalContext.onClose();
    if (asset) movePageByAssetAndType(asset?.id, type);
  };

  const movePageByAssetAndType = (assetId: number, type: string) => {
    switch (type) {
      case 'createWorkOrder':
        if (workOrderCreateButtonClicked) {
          workOrderCreateButtonClicked(assetId);
        }
        navigate(`/?newWorkOrderAssetId=${assetId}#newWorkOrder`);
        break;
      case 'createRequest':
        navigate(`/requests/new?newRequestAssetId=${assetId}`);
        break;
      case 'detail':
        navigate(`/assets#${assetId}`);
        break;
      default:
        throw new Error('Invalid type');
    }
  };

  return (
    <ModalContent>
      <ModalHeader fontSize='md'>
        {t('scanned-asset')}
        <ModalCloseButton
          onClick={() => {
            onClose();
            modalContext.onClose();
          }}
        />
      </ModalHeader>
      <ModalBody>
        <Flex direction='column' alignItems='center' gap={4}>
          <Text>{formatAssetName(asset)}</Text>

          <Flex direction='column' alignItems='center' gap={2} width='full'>
            {optionChildren?.map((btn) => {
              return (
                <Button
                  key={btn.type}
                  {...btn.props}
                  variant='outline'
                  onClick={() => onOptionClicked(btn.type)}
                  width='full'
                  gap={1}
                >
                  {btn.icon && <Icon fontSize='sm' as={btn.icon} />}
                  {t(btn.textKey)}
                </Button>
              );
            })}
          </Flex>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button
          colorScheme='gray'
          onClick={() => {
            onReScan();
            modalContext.onClose();
          }}
        >
          {t('actions.re-scan')}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

const QrScannerModalErrorContent = ({ onReScan }: { onReScan: () => void }) => {
  const { t, t_errors } = useTranslation();
  const modalContext = useModalContext();

  return (
    <ModalContent>
      <ModalBody p='6'>
        <Flex direction='column' alignItems='center'>
          <Text mb='3'>{t_errors('asset-does-not-exist')}</Text>
          <Button
            colorScheme='gray'
            onClick={() => {
              modalContext.onClose();
              onReScan();
            }}
          >
            {t('actions.re-scan')}
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};

export default QrScannerModalContent;
